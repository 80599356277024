.calc {
  @apply relative mx-auto max-w-[50rem] rounded-lg;
  color: $color-btns-container;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 5px;
}

.calc__display {
  @apply grid items-center bg-pinned-1 text-right;
  font-size: 1.9rem;
  padding: 1.5em 2rem 1em;
  grid-template: 1fr 1fr / 1fr 2fr;
}

.display__operation {
  grid-area: 2/1;
}

.display__holder {
  grid-area: 1/2;
  font-size: 1.6rem;
  opacity: 0.6;
}

.display__next {
  grid-area: 2/2;
}

.calc__btns-container {
  @apply grid gap-[0.4rem] rounded-b-md bg-white p-[0.8rem];
  grid-template-columns: repeat(4, 1fr);
}

.calc__btn {
  @apply cursor-pointer border-none bg-gray-2;
  color: $color-display;
  padding: 1.5em 2rem;
  border-radius: 0.2rem;
}

.btn--span-2 {
  grid-column: span 2;
}

.btn--colored {
  @apply bg-primary;
  color: $color-display;
}

.warning {
  @apply absolute left-0 top-0 z-[-1] flex h-full w-full flex-col items-center rounded-[0.5rem] p-[10rem] text-center opacity-0;
  background: $color-warning;
  transition:
    z-index 0ms,
    opacity 150ms ease-in-out;
}

.warning--active {
  @apply z-[1] opacity-[1];
}

.warning .fas {
  font-size: 7.5rem;
  margin-bottom: 2rem;
}

.warning p {
  font-size: 2.5rem;
  font-weight: 900;
}
